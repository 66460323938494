import React from 'react'
import TrainingTemplate from 'components/templates/TrainingTemplate/TrainingTemplate'

export default function TrainingQltQualityPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Qualità QLT'

  const description =
    '<b>INTRODUZIONE AI SISTEMI DI GESTIONE QUALITA’ </b><br><br> Il corso ha lo scopo di fornire ai partecipanti le conoscenze necessarie per apprendere i rudimenti dei sistemi di gestione per la qualità ai fini della certificazione secondo la norma UNI EN ISO 9001: 2015. Intende quindi partire da un’analisi sistemica delle norme di riferimento arrivando a definire ed analizzare la documentazione di sistema (Manuale qualità, procedure operative, istruzioni di lavoro, modulistica, piani della qualità, etc) Allo scopo di agevolare l’apprendimento delle tematiche trattate è prevista l’esecuzione di alcune esercitazioni (simulazioni pratiche) che l’analisi di casi aziendali.<br> in particolare vengono affrontati  temi inerenti:<br><br> <b>I concetti base sui Sistemi di Gestione per la Qualità e norme di riferimento</b><br><br> L’approccio per processi<br> Il riesame del Sistema di Gestione per la Qualità<br> Il miglioramento del Sistema di Gestione per la Qualità e le tecniche statistiche<br> I documenti e le registrazioni del Sistema di Gestione per la Qualità'

  const description1 = null

  return (
    <>
      <TrainingTemplate
        seoKeywords={seoKeywords}
        productName={productName}
        description={description}
        description1={description1}
      />
    </>
  )
}
